var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "el-fade-in" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible"
          }
        ],
        staticClass: "goods-specs-mask"
      },
      [
        _c("transition", { attrs: { name: "modal" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible"
                }
              ],
              staticClass: "goods-specs"
            },
            [
              _c("div", { staticClass: "goods-specs-hader" }, [
                _c("h1", [_vm._v("选择班型")]),
                _c("i", {
                  staticClass: "el-icon-close",
                  on: {
                    click: function($event) {
                      return _vm.$emit("on-cancel")
                    }
                  }
                })
              ]),
              _c(
                "div",
                { staticClass: "goods-specs-body" },
                [
                  _vm.currentGoodsRank === 1 || _vm.currentGoodsRank === 3
                    ? _c("default-spec-panel", {
                        ref: "defaultSpecPanel",
                        attrs: {
                          currentGoodsRank: _vm.currentGoodsRank,
                          specTypeArr: _vm.specTypeArr2,
                          specPriceArr: _vm.specPriceArr2,
                          specArray: _vm.specArray
                        }
                      })
                    : _vm.currentGoodsRank === 2 || _vm.currentGoodsRank === 4
                    ? _c("single-more-spec-panel", {
                        ref: "singleMoreSpecPanel",
                        attrs: {
                          currentGoodsRank: _vm.currentGoodsRank,
                          specTypeArr: _vm.specTypeArr,
                          specPriceArr: _vm.specPriceArr,
                          specArray: _vm.specArray
                        }
                      })
                    : _vm.currentGoodsRank === 5
                    ? _c("meal-more-spec-panel", {
                        ref: "mealMoreSpecPanel",
                        attrs: {
                          currentGoodsRank: _vm.currentGoodsRank,
                          specTypeArr: _vm.specTypeArr,
                          specPriceArr: _vm.specPriceArr,
                          specCountArr: _vm.specCountArr,
                          specArray: _vm.specArray
                        },
                        on: {
                          "update:specPriceArr": function($event) {
                            _vm.specPriceArr = $event
                          },
                          "update:spec-price-arr": function($event) {
                            _vm.specPriceArr = $event
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "goods-specs-footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { gap: 18 },
                      on: {
                        click: function($event) {
                          return _vm.$emit("on-cancel")
                        }
                      }
                    },
                    [_vm._v("返回")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onOk } },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }