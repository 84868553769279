<!--
 * @Author: 刘锦
 * @Date: 2020-11-07 19:59:14
 * @LastEditTime: 2022-03-09 09:56:05
 * @LastEditors: Seven
 * @Description: 
 * @FilePath: \workbench-front\src\components\business\single-more-spec-panel.vue
-->
<template>
  <div class="single-more-spec-wrap">
    <!-- 单品多规格 规格类型数组长等于一 -->
    <div class="single-one" v-if="specTypeArr.length === 1">
      <div class="content" v-for="item of specTypeArr" :key="item.id">
        <h2 class="cate-name" >{{ item.specificationTypeName }}</h2>
        <!-- 套餐单规格 -->
        <div class="spec-list">
          <template v-for="subItem of item.specificationNameArr">
              <div v-if="specArray.some(i => i.id == subItem.id)" :class="['spec-item', { active: subItem.id === curSelectedSpecId }]"  :key="subItem.id"
                @click="onSpecItemClick(subItem)">
                <div class="spec-name">{{ subItem.specificationName }}</div>
                <img src="@/assets/image/select-ok-mini.png" v-show="subItem.id === curSelectedSpecId" />
              </div>
          </template>
          
        </div>
      </div>
    </div>
    <!-- 单品多规格 规格类型数组长等于二 -->
    <div class="single-two" v-else-if="specTypeArr.length === 2">
      <div class="content">
        <h2 class="cate-name">{{ specTypeArr[0].specificationTypeName }}</h2>
        <div class="spec-list">
          <template v-for="subItem of specTypeArr[0].specificationNameArr">
              <div 
              :class="['spec-item', { active: subItem.id === curSelectedSpecIdOne }]"
              :key="subItem.id"
              @click="onSpecOneItemClick(subItem)"
            >
              <div class="spec-name">{{ subItem.specificationName }}</div>
              <img src="@/assets/image/select-ok-mini.png" v-show="subItem.id === curSelectedSpecIdOne" />
            </div>
          </template>
          
        </div>
      </div>
      <div class="content">
        <h2 class="cate-name">{{ specTypeArr[1].specificationTypeName }}</h2>
        <div class="spec-list">
          <template v-for="subItem of specTypeArr[1].specificationNameArr">
            <div
            :class="['spec-item', { active: subItem.spNameId === curSelectedSpecIdTwo }]"
            :key="subItem.spNameId"
            @click="onSpecTwoItemClick(subItem)"
          >
            <div class="spec-name">{{ subItem.specificationName ? subItem.specificationName :subItem.spName }}</div>
            <img src="@/assets/image/select-ok-mini.png" v-show="subItem.spNameId === curSelectedSpecIdTwo" />
          </div>
          </template>
        </div>
      </div>
    </div>
    <!-- <div class="hr"></div> -->
    <div class="spec-bottom">
      <!-- <div class="already-select">
        <h3>已选</h3>
        <ul class="select-list">
          <transition-group name="label">
            <li class="select-item" key="specificationName">
              <span>{{ specificationName }}</span>
            </li>
          </transition-group>
        </ul>
      </div> -->
      <div class="price-line">
        <p class="price-title">价格</p>
        <p class="price-text">{{ "￥" + (totalPrice || 0) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
// 单品多规格面板
export default {
  name: "SingleMoreSpecPanel",
  props: {
    currentGoodsRank: Number,
    specTypeArr: Array,
    specPriceArr: Array,
    specArray: Array
  },
  data() {
    return {
      curSelectedSpecId: "",
      curSelectedSpecIdOne: "",
      curSelectedSpecIdTwo: "",
      specificationName: "",
      totalPrice: 0,
      specIdArr: [],
      specArr: [],
      specTypeArray: []
    }
  },
  computed: {
    specPriceArrIds() {
      let arr = []
      this.specPriceArr.forEach(item => {
        arr.push(item.id)
      })
      return arr
    }
  },
  watch: {
    // 默认选中第一个
    specTypeArr(data) {

      if(!data) return
      if (data.length === 1) {
        this.onSpecItemClick(data[0].specificationNameArr[0])
      } else if (data.length === 2) {
        this.onSpecOneItemClick(data[0].specificationNameArr[0])
      }
    },

    /***
     * 进行数据的默认选中
     */
    specArray(data) {
      if (this.specTypeArray.length === 2) {
        this.specTypeArray[1].specificationNameArr = data[0].secondSpNameList
        this.onSpecTwoItemClick(data[0].secondSpNameList[0])
      }
    },
    specPriceArr() {
      this.calcSpecData()
    }
  },
  methods: {
    calcSpecData() {
      const curSpec = this.getCurSpecObj()
      console.log("curSpeccurSpeccurSpeccurSpeccurSpeccurSpec",curSpec)
      this.specificationName = this.getSpecificationName(curSpec)
      this.totalPrice = this.getTotalPrice(curSpec)
      this.specIdArr = this.getSpecIdArr(curSpec)
      this.specArr = [curSpec]
    },
    // 单个规格项点击
    onSpecItemClick(spec) {
      if( spec.isStop ){
        this.$message.error("此班型已经停用,不允许使用")
        return;
      }
      this.curSelectedSpecId = spec.id
      this.calcSpecData()
      this.$bus.$emit("setFirstCateInfo",spec);
    },
    onSpecOneItemClick(spec) {
      if( spec.isStop ){
        this.$message.error("此班型已经停用,不允许使用")
        return;
      }
      console.log('spec one', spec, this.specArray);
      this.specTypeArray = this.specTypeArr
      this.specArray.map((item => {
        if (item.id == spec.id) {
          this.specTypeArray[1].specificationNameArr = item.secondSpNameList
        }
      }))
      this.curSelectedSpecIdOne = spec.id
      if (!this.curSelectedSpecIdTwo) return
      this.calcSpecData()
    },
    onSpecTwoItemClick(spec) {
      if( spec.isStop ){
        this.$message.error("此班型已经停用,不允许使用")
        return;
      }
      console.log('spec two', spec, this.specArray);
      if (!this.curSelectedSpecIdOne) {
        return this.$message.error('请选择第一行')
      }
      this.curSelectedSpecIdTwo = spec.spNameId
      this.calcSpecData()
    },
    getCurSpecObj() {
      console.log('this.specPriceArr333', this.specPriceArr)
      console.log('this.specTypeArr', this.specTypeArr)
      console.log('this.curSelectedSpecIdOne', this.curSelectedSpecIdOne)
      console.log('this.curSelectedSpecIdTwo', this.curSelectedSpecIdTwo)
      let curSpec
      if (this.specTypeArr.length === 1) {
        curSpec = this.specPriceArr.find(item => item.specificationNameId1 === this.curSelectedSpecId)
      } else if (this.specTypeArr.length === 2) {
        curSpec = this.specPriceArr.find(
          item => {
            console.log("item",item)
            return item.specificationNameId1 === this.curSelectedSpecIdOne && item.specificationNameId2 === this.curSelectedSpecIdTwo

          }
        )
        console.log('curSpec', curSpec);
      }
      return curSpec
    },
    getSpecificationName(curSpec) {
      console.log('组件内curSpec555555', curSpec)
      if(!curSpec) return ''
      if (this.specTypeArr.length === 1) {
        return curSpec.specificationName1
      } else if (this.specTypeArr.length === 2) {
        return `${curSpec.specificationName1}(${curSpec.specificationName2})`
      }
    },
    getTotalPrice(curSpec) {
      if(!curSpec) return
      if (this.currentGoodsRank === 2) {
          return curSpec.goodsPrice
      } else if (this.currentGoodsRank === 4) {
        return curSpec.goodsDiscountPrice
      }
    },
    getSpecIdArr(curSpec) {
      if(!curSpec) return
      if (this.specTypeArr.length === 1) {
        return [curSpec.id]
      } else if (this.specTypeArr.length === 2) {
        return [curSpec.id]
      }
    },
    //商品价格变动时自动选择
    autoSelOnPriceChange(){
      // console.log("查询",this.specTypeArray[1].specificationNameArr)
      // console.log("查询一级类",this.specTypeArr)
      // this.curSelectedSpecIdOne = val.id
      // console.log("商品价格变动>>>>>>>>>>>>>>>>>",val,this.curSelectedSpecIdOne);
      // for(let i of this.$bus)
      //模拟手动点击，找回商品的大类
      this.onSpecItemClick(this.$bus.firstCateInfo)
      //找回商品的小类
      // for(let i of this.specTypeArray[1].specificationNameArr){
      //   if(this.$bus.secondCateInfo.includes(i.spNameId)){
      //     this.onSpecTwoItemClick(i)
      //   }
      // }
    }
  },
  mounted(){

    // this.$bus.$on("autoSelOnPriceChange",this.autoSelOnPriceChange);
  }
}
</script>

<style lang="scss" scoped>
.single-one {
  position: relative;
  margin-bottom: 14px;
  .default {
    display: inline-block;
    padding: 0 24px;
    height: 30px;
    line-height: 30px;
    color: #337AFF;
    background: #ffffff;
    border: 1px solid #337AFF;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
  }
  .single-icon {
    position: absolute;
    left: 87px;
    top: 0;
  }
  .content {
    margin-bottom: 18px;
    .cate-name {
      margin-right: 24px;
      margin-bottom: 12px;
      font-size: 14px;
      color: #999999;
      white-space: nowrap;
      float: left; // add
      min-width: 28px;
      text-align: right;
    }
    .spec-list {
      display: flex;
      flex-wrap: wrap;
    }
    .spec-item {
      display: inline-block;
      position: relative;
      width: fit-content;
      height: 30px;
      line-height: 30px;
      padding: 0 20px;
      text-align: center;
      margin-right: 12px;
      margin-bottom: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      color: #666666;
      cursor: pointer;
      .spec-name {
        font-size: 14px;
      }

      > img {
        position: absolute;
        right: -1px;
        top: 0;
      }
    }
    .active {
      // color: #337AFF;
      // border: 1px solid #337AFF;
      color: #337AFF;
      border: 1px solid #337AFF;
      background: #E5F2FF;
    }
  }
}
.single-two {
  position: relative;
  margin-bottom: 14px;
  .default {
    display: inline-block;
    padding: 0 24px;
    height: 30px;
    line-height: 30px;
    color: #337AFF;
    background: #ffffff;
    border: 1px solid #337AFF;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
  }
  .single-icon {
    position: absolute;
    left: 87px;
    top: 0;
  }
  .content {
    margin-bottom: 18px;
    .cate-name {
      margin-right: 24px;
      margin-bottom: 12px;
      font-size: 14px;
      color: #999999;
      white-space: nowrap;
    }
    .spec-list {
      display: flex;
      flex-wrap: wrap;
    }
    .spec-item {
      display: inline-block;
      position: relative;
      width: fit-content;
      height: 30px;
      line-height: 30px;
      padding: 0 25px;
      text-align: center;
      margin-right: 12px;
      margin-bottom: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      color: #666666;
      cursor: pointer;
      .spec-name {
        font-size: 14px;
      }

      > img {
        position: absolute;
        right: -1px;
        top: 0;
      }
    }
    .active {
      color: #337AFF;
      border: 1px solid #337AFF;
    }
  }
}
.hr {
  width: 616px;
  margin: 0 auto;
  margin-bottom: 30px;
  border-bottom: 1px dashed #e0e0e0;
}
.price-line {
  text-align: right;
  .price-title {
    // margin-bottom: 12px;
    font-size: 14px;
    color: #999999;
    display: inline-block;
    margin-right: 24px;
  }
  .price-text {
    color: #f54955;
    font-size: 18px;
    font-weight: 700;
    display: inline-block;
  }
}
.spec-bottom{
  display: flex;
  justify-content: space-between;
}
</style>
