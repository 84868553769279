<!--
 * @Author: 刘锦
 * @Date: 2020-09-18 10:17:57
 * @LastEditTime: 2022-02-23 10:32:11
 * @LastEditors: Seven
 * @Description: 
 * @FilePath: \workbench-front\src\components\business\goods-specs-modal.vue
-->
<template>
  <transition name="el-fade-in">
    <div class="goods-specs-mask" v-show="visible">
      <transition name="modal">
        <div class="goods-specs" v-show="visible">
          <div class="goods-specs-hader">
            <h1>选择班型</h1>
            <!-- <i class="iconfont icondelete1" @click="$emit('on-cancel')"></i> -->
            <i class="el-icon-close" @click="$emit('on-cancel')"></i>
          </div>
          <div class="goods-specs-body">
            <!-- 单品默认规格，0行，1行，2行，套餐默认规格0行，1行，2行。 -->
            <default-spec-panel
              v-if="currentGoodsRank === 1 || currentGoodsRank === 3"
              :currentGoodsRank="currentGoodsRank"
              :specTypeArr="specTypeArr2"
              :specPriceArr="specPriceArr2"
              :specArray="specArray"
              ref="defaultSpecPanel"
            />
            <!-- 单品多规格，套餐单规格 -->
            <single-more-spec-panel
              v-else-if="currentGoodsRank === 2 || currentGoodsRank === 4"
              :currentGoodsRank="currentGoodsRank"
              :specTypeArr="specTypeArr"
              :specPriceArr="specPriceArr"
              :specArray="specArray"
              ref="singleMoreSpecPanel"
            />
            <!--套餐多规格多选 -->
            <meal-more-spec-panel
              v-else-if="currentGoodsRank === 5"
              :currentGoodsRank="currentGoodsRank"
              :specTypeArr="specTypeArr"
              :specPriceArr.sync="specPriceArr"
              :specCountArr="specCountArr"
              :specArray="specArray"
              ref="mealMoreSpecPanel"
            />
          </div>
          <div class="goods-specs-footer">
            <el-button :gap="18" @click="$emit('on-cancel')">返回</el-button>
            <el-button type="primary" @click="onOk" >确定</el-button>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import defaultSpecPanel from "./default-spec-panel"
import singleMoreSpecPanel from "./single-more-spec-panel"
import mealMoreSpecPanel from "./meal-more-spec-panel"
export default {
  name: "GoodsSpecsModal",
  components: {
    defaultSpecPanel,
    singleMoreSpecPanel,
    mealMoreSpecPanel
  },
  data() {
    return {
      // 考期数组
      examPeriods: [],
      // 当前考期对应的科目
      subjects: [],
      // 单品多规格一行，当前选择的specid
      curSelectedSpecId: "",
      // 单品多规格二行，二行分别选择的specId
      curSelectedSpecIdOne: "",
      curSelectedSpecIdTwo: "",
      // 规格名称
      specificationName: '',
      // 规格信息数组
      specInfoArr: [],
      sortNum: null
    }
  },
  props: {
    visible: Boolean,
    specTypeArr: Array,//第一行
    specPriceArr: Array,
    currentGoodsRank: [Number],
    specCountArr: Array,
    specArray: Array
  },
  computed: {
    specTypeArr2(){
      const specTypeArr = JSON.parse(JSON.stringify(this.specTypeArr))
      console.log('specTypeArr', specTypeArr);
      console.log('specTypeArr.length',specTypeArr.length);
      if(specTypeArr.length === 2){
        const specificationNameArr = specTypeArr[1].specificationNameArr || []
        console.log('specificationNameArr', specificationNameArr);
        // console.log('[this.specPriceArr]', this.specPriceArr);
        // console.log('[specificationNameArr]', specificationNameArr);
        this.specPriceArr.map(priceItem =>{
          const index = specificationNameArr.findIndex(specNameItem=>{
            // console.log(' [name]', priceItem.specificationName1, priceItem.specificationName2, '[specNameItem]', specNameItem.id, ' [priceItem]', priceItem.specificationNameId2, priceItem.status);
            return specNameItem.id == priceItem.specificationNameId2 && priceItem.status == 0 ? true : false 
          })
          if(index > -1){
            specificationNameArr.splice(index, 1)
          }
        })
        specTypeArr[1].specificationNameArr = specificationNameArr
      }
      console.log('specTypeArr2', specTypeArr);
      return specTypeArr
    },
    specPriceArr2(){
      const specPriceArr = JSON.parse(JSON.stringify(this.specPriceArr))
      const distSpecPriceArr = specPriceArr.filter(item => item.status == 1)
      return distSpecPriceArr
    }
  },
  methods: {
    // 选择规格确定
    onOk() {
      const specInfo = {
        // 计算出的总价
        totalPrice: 0,
        // 规格名称数组
        specificationName: '',
        // 规格id数组,
        specIdArr: [],
        // 整体的规格数组
        specArr: []
      }
      let totalPrice
      let specificationName
      let specIdArr
      let specArr
      // 单品默认规格 ,套餐默认规格
      if (this.currentGoodsRank === 1 || this.currentGoodsRank === 3) {
        specIdArr = this.specPriceArr2.map(item => item.id)
        specificationName = this.$refs.defaultSpecPanel.specificationName
        if (this.currentGoodsRank === 1) {
          totalPrice = this.specPriceArr2.reduce((total, item) => total + (isNaN(+item.goodsPrice) ? 0 : +item.goodsPrice), 0)
        } else {
          totalPrice = this.specPriceArr2.reduce((total, item) => total + (isNaN(+item.goodsDiscountPrice) ? 0 : +item.goodsDiscountPrice), 0)
        }
        specArr = this.specPriceArr2.slice()
      }
      // 单品多规格，1行和2行处理
      else if (this.currentGoodsRank === 2 || this.currentGoodsRank === 4) {
        // 此条件为单品多规格两行，未选第二行的情况
        specificationName = this.$refs.singleMoreSpecPanel.specificationName
        if (!specificationName) {
          return this.$message.error("选择不完整！")
        }
        totalPrice = this.$refs.singleMoreSpecPanel.totalPrice
        specIdArr = this.$refs.singleMoreSpecPanel.specIdArr
        specArr = this.$refs.singleMoreSpecPanel.specArr
      }
      // 套餐多规格多选的情况
      else if (this.currentGoodsRank === 5) {
        // 此条件为套餐多规格多选，未选第二行的情况
        specificationName = this.$refs.mealMoreSpecPanel.specificationName
        if (!specificationName) {
          return this.$message.error("选择不完整！")
        }
        totalPrice = this.$refs.mealMoreSpecPanel.totalPrice
        specIdArr = this.$refs.mealMoreSpecPanel.specIdArr
        specArr = this.$refs.mealMoreSpecPanel.specArr
        // 商品套餐多规格
        const specIdTwoArr = this.$refs.mealMoreSpecPanel.specIdTwoArr
        const curSelectedSpecIdOne = this.$refs.mealMoreSpecPanel.curSelectedSpecIdOne
        if(this.specTypeArr.length > 1){
          const currentLimitCountNum = this.specCountArr.find(item => item.specificationId === curSelectedSpecIdOne).selectCount
          console.log("选择的数量",currentLimitCountNum)
          if (currentLimitCountNum !== 0 && specIdTwoArr.length > currentLimitCountNum) {
            specIdTwoArr.pop()
            return this.$message.error("数量超出该套餐可选最大规格数")
          } else if (currentLimitCountNum !== 0 && specIdTwoArr.length < currentLimitCountNum) {
            return this.$message.error(`至少选择${currentLimitCountNum}项科目`)
          }
        }else{
          //存在只有规则一项可以选择的情况
          const currentLimitCountNum = this.specCountArr[0].selectCount
          // 选择不满可选数量
          if(currentLimitCountNum !== 0 && specIdArr.length < currentLimitCountNum){
            return this.$message.error(`请选择${currentLimitCountNum}个规格`)
          }
          // 选择超出可选数量
          if(currentLimitCountNum !== 0 && specIdArr.length > currentLimitCountNum){
            return this.$message.error("数量超出该套餐可选最大规格数")
          }
        }
      }
      specInfo.totalPrice = totalPrice
      specInfo.specIdArr = specIdArr
      specInfo.specificationName = specificationName
      specInfo.specArr = specArr
      console.log(specInfo, "---------------");
      this.$emit("onSpecModalOk", specInfo)
    },
    // 单个规格项点击
    onSpecItemClick(spec) {
      this.curSelectedSpecId = spec.id
    },
    onSpecOneItemClick(spec) {
      this.curSelectedSpecIdOne = spec.id
    },
    onSpecTwoItemClick(spec) {
      this.curSelectedSpecIdTwo = spec.id
    }
  },
  watch: {
    currentGoodsRank(rank) {
      /**
       *
       *
       * 1、单品-默认规格
        2、单品-多规格
        3、套餐-默认规格
        4、套餐-多规格单选
        5、套餐-多规格多选
       *
       */
      console.log("rank :>> ", rank)
    }
  }
}
</script>

<style lang="scss" scoped>
// @import "@/assets/css/variable.scss";
.goods-specs-mask {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  .goods-specs {
    width: 676px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 12px 24px 0px rgba(112, 112, 112, 0.24);

    &-hader {
      position: relative;
      width: 100%;
      height: 56px;
      line-height: 63px;
      padding-left: 30px;

      > h1 {
        font-size: 16px;
        font-weight: 700;
        color: #333;
      }
      > i {
        position: absolute;
        top: 20px;
        right: 18px;
        font-size: 18px;
        color: #9bacbf;
        transition: 0.2s;
        cursor: pointer;
        // &:hover {
        //   transform: rotate(90deg);
        // }
      }
    }
    .goods-specs-body {
      width: 100%;
      padding: 30px 24px 0 24px;
      border-top: 1px solid #e1e4eb;
      // border-bottom: 1px solid #e1e4eb;
      .hr {
        width: 616px;
        margin: 0 auto;
        margin-bottom: 30px;
        border-bottom: 1px dashed #e0e0e0;
      }
      ::v-deep .already-select {
        margin-bottom: 18px;
        > h3 {
          margin-right: 24px;
          margin-bottom: 12px;
          font-size: 14px;
          color: #999;
        }
        .select-list {
          .select-item {
            font-size: 14px;
            color: #333;
            margin-bottom: 12px;
          }
        }
        .signed{
          margin-left: 10px;
          color: #f54955;
          font-size: 12px;
        }
      }
    }
    &-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 84px;
    }
  }
}
.single-default {
  position: relative;
  margin-bottom: 14px;
  .default {
    display: inline-block;
    padding: 0 24px;
    height: 30px;
    line-height: 30px;
    color: #337aff;
    background: #ffffff;
    border: 1px solid #337aff;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
  }
  .single-icon {
    position: absolute;
    left: 87px;
    top: 0;
  }
  .content {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    .cate-name {
      margin-right: 24px;
      font-size: 14px;
      color: #999999;
    }
    .spec-list {
      display: flex;
    }
    .spec-item {
      position: relative;
      width: fit-content;
      height: 30px;
      line-height: 30px;
      padding: 0 24px;
      margin-right: 12px;
      border: 1px solid #337aff;
      border-radius: 3px;
      cursor: pointer;
      .spec-name {
        font-size: 14px;
        color: #337aff;
      }
      > img {
        position: absolute;
        right: -1px;
        top: 0;
      }
    }
  }
}
.single-one {
  position: relative;
  margin-bottom: 14px;
  .default {
    display: inline-block;
    padding: 0 24px;
    height: 30px;
    line-height: 30px;
    color: #337aff;
    background: #ffffff;
    border: 1px solid #337aff;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
  }
  .single-icon {
    position: absolute;
    left: 87px;
    top: 0;
  }
  .content {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    .cate-name {
      margin-right: 24px;
      font-size: 14px;
      color: #999999;
    }
    .spec-list {
      display: flex;
    }
    .spec-item {
      position: relative;
      width: fit-content;
      height: 30px;
      line-height: 30px;
      padding: 0 24px;
      margin-right: 12px;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      color: #666666;
      cursor: pointer;
      .spec-name {
        font-size: 14px;
      }

      > img {
        position: absolute;
        right: -1px;
        top: 0;
      }
    }
    .active {
      color: #337aff;
      border: 1px solid #337aff;
    }
  }
}
.single-two {
  height: 165px;
  position: relative;
  margin-bottom: 14px;
  .default {
    display: inline-block;
    padding: 0 24px;
    height: 30px;
    line-height: 30px;
    color: #337aff;
    background: #ffffff;
    border: 1px solid #337aff;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
  }
  .single-icon {
    position: absolute;
    left: 87px;
    top: 0;
  }
  .content {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    .cate-name {
      margin-right: 24px;
      font-size: 14px;
      color: #999999;
    }
    .spec-list {
      display: flex;
    }
    .spec-item {
      position: relative;
      width: fit-content;
      height: 30px;
      line-height: 30px;
      padding: 0 24px;
      margin-right: 12px;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      color: #666666;
      cursor: pointer;
      .spec-name {
        font-size: 14px;
      }

      > img {
        position: absolute;
        right: -1px;
        top: 0;
      }
    }
    .active {
      color: #337aff;
      border: 1px solid #337aff;
    }
  }
}
.label-enter-active,
.label-leave-active {
  transition: 0.2s;
}
.label-enter {
  transform: translate(-10px);
  opacity: 0;
}
.label-leave-to {
  transform: translate(10px);
  opacity: 0;
}
</style>
