var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "meal-more-spec-wrap" }, [
    _vm.specTypeArr.length === 1
      ? _c(
          "div",
          { staticClass: "meal-one" },
          _vm._l(_vm.specTypeArr, function(item) {
            return _c("div", { key: item.id, staticClass: "content" }, [
              _c("h2", { staticClass: "cate-name" }, [
                _vm._v(_vm._s(item.specificationTypeName))
              ]),
              _c(
                "div",
                { staticClass: "spec-list" },
                [
                  _vm._l(item.specificationNameArr, function(subItem) {
                    return [
                      _vm.specArray.some(function(i) {
                        return i.id == subItem.id
                      })
                        ? _c(
                            "div",
                            {
                              key: subItem.id,
                              class: [
                                "spec-item",
                                {
                                  active: _vm.specNameIdArr.includes(subItem.id)
                                }
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.onSpecItemClick(subItem)
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "spec-name" }, [
                                _vm._v(_vm._s(subItem.specificationName))
                              ]),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.specNameIdArr.includes(
                                      subItem.id
                                    ),
                                    expression:
                                      "specNameIdArr.includes(subItem.id)"
                                  }
                                ],
                                attrs: {
                                  src: require("@/assets/image/select-ok-mini.png")
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ])
          }),
          0
        )
      : _vm.specTypeArr.length === 2
      ? _c("div", { staticClass: "meal-two" }, [
          _c("div", { staticClass: "content" }, [
            _c("h2", { staticClass: "cate-name" }, [
              _vm._v(_vm._s(_vm.specTypeArr[0].specificationTypeName))
            ]),
            _c(
              "div",
              { staticClass: "spec-list" },
              [
                _vm._l(_vm.specTypeArr[0].specificationNameArr, function(
                  subItem
                ) {
                  return [
                    _vm.specArray.some(function(i) {
                      return i.id == subItem.id
                    })
                      ? _c(
                          "div",
                          {
                            key: subItem.id,
                            class: [
                              "spec-item",
                              {
                                active: subItem.id === _vm.curSelectedSpecIdOne
                              }
                            ],
                            on: {
                              click: function($event) {
                                return _vm.onSpecOneItemClick(subItem, true)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "spec-name" }, [
                              _vm._v(_vm._s(subItem.specificationName))
                            ]),
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    subItem.id === _vm.curSelectedSpecIdOne,
                                  expression:
                                    "subItem.id === curSelectedSpecIdOne"
                                }
                              ],
                              attrs: {
                                src: require("@/assets/image/select-ok-mini.png")
                              }
                            })
                          ]
                        )
                      : _vm._e()
                  ]
                })
              ],
              2
            )
          ]),
          _c("div", { staticClass: "content" }, [
            _c("h2", { staticClass: "cate-name" }, [
              _vm._v(_vm._s(_vm.specTypeArr[1].specificationTypeName))
            ]),
            _vm.specTypeArray.length !== 0
              ? _c(
                  "div",
                  { staticClass: "spec-list" },
                  _vm._l(_vm.specTypeArray[1].specificationNameArr, function(
                    subItem
                  ) {
                    return _c(
                      "div",
                      {
                        key: subItem.spNameId,
                        class: [
                          "spec-item",
                          {
                            active: _vm.specIdTwoArr.includes(subItem.spNameId)
                          }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.onSpecTwoItemClick(subItem)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "spec-name" }, [
                          _vm._v(_vm._s(subItem.spName))
                        ]),
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.specIdTwoArr.includes(
                                subItem.spNameId
                              ),
                              expression:
                                "specIdTwoArr.includes(subItem.spNameId)"
                            }
                          ],
                          attrs: {
                            src: require("@/assets/image/select-ok-mini.png")
                          }
                        })
                      ]
                    )
                  }),
                  0
                )
              : _c(
                  "div",
                  { staticClass: "spec-list" },
                  _vm._l(_vm.specTypeArr[1].specificationNameArr, function(
                    subItem
                  ) {
                    return _c(
                      "div",
                      {
                        key: subItem.id,
                        class: [
                          "spec-item",
                          { active: _vm.specIdTwoArr.includes(subItem.id) }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.onSpecTwoItemClick(subItem)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "spec-name" }, [
                          _vm._v(_vm._s(subItem.specificationName))
                        ]),
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.specIdTwoArr.includes(subItem.id),
                              expression: "specIdTwoArr.includes(subItem.id)"
                            }
                          ],
                          attrs: {
                            src: require("@/assets/image/select-ok-mini.png")
                          }
                        })
                      ]
                    )
                  }),
                  0
                )
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "hr" }),
    _c("div", { staticClass: "spec-bottom" }, [
      _c("div", { staticClass: "already-select" }, [
        _c("h3", [_vm._v("已选")]),
        _c(
          "ul",
          { staticClass: "select-list" },
          [
            _c("transition-group", { attrs: { name: "label" } }, [
              _c(
                "li",
                { key: "specificationName", staticClass: "select-item" },
                [_c("span", [_vm._v(_vm._s(_vm.specificationName))])]
              )
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "price-line" }, [
        _c("p", { staticClass: "price-title" }, [_vm._v("价格")]),
        _c("p", { staticClass: "price-text" }, [
          _vm._v(_vm._s("￥" + (_vm.totalPrice || 0)))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }