<!--
 * @Author: 刘锦
 * @Date: 2020-11-07 19:14:05
 * @LastEditTime: 2022-03-12 18:03:05
 * @LastEditors: Seven
 * @Description: 
 * @FilePath: \workbench-front\src\components\business\default-spec-panel.vue
-->
<template>
  <div class="single-default-wrap">
    <!-- 单品默认规格 -->
    <div class="single-default" v-if="specTypeArr.length <= 1">
      <div class="spec-item">
        <div class="spec-name" v-if="specTypeArr[0]">{{ specTypeArr[0].specificationNameArr[0].specificationName }}</div>
        <div class="spec-name" v-else-if="!specTypeArr.length && this.specificationName">{{ this.specificationName }}</div>
        <img src="@/assets/image/select-ok-mini.png" alt=""/>
      </div>
    </div>
    <!-- 单品默认规格类型数组长为1，长为2 -->
    <div class="single-default" v-else-if="specTypeArr.length >1">
      <div class="content" v-for="(item, index) of specTypeArr" :key="item.id">
        <h2 class="cate-name">{{ item.specificationTypeName }}</h2>
        <div class="spec-list">
          <template v-for="subItem of item.specificationNameArr">  
            <div class="spec-item" v-if="index == 1 && specArray[0] && specArray[0].secondSpNameList.some(i => i.spNameId == subItem.id)" :key="subItem.id">
              <div class="spec-name">{{ subItem.specificationName }}</div>
              <img src="@/assets/image/select-ok-mini.png" />
            </div>
            <div class="spec-item" v-if="index == 0" :key="subItem.id">
              <div class="spec-name">{{ subItem.specificationName }}</div>
              <img src="@/assets/image/select-ok-mini.png" />
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="hr"></div>
    <div class="spec-bottom">
      <div class="already-select">
        <h3>已选</h3>
        <ul class="select-list">
          <transition-group name="label">
            <li class="select-item" key="specificationName" v-if="specTypeArr[0]">
              <span>{{ specificationName || specTypeArr[0].specificationNameArr[0].specificationName }}</span>
            </li>
            <!-- 单品默认规格 -->
            <li class="select-item" v-else-if="!specTypeArr.length && specificationName" key="specificationName"><span>{{ specificationName }}</span></li>
          </transition-group>
        </ul>
      </div>
      <!-- 单品默认规格 -->
      <div class="price-line">
        <p class="price-title">价格</p>
        <p class="price-text">{{ "￥" + (totalPrice || 0 ) }}</p>
      </div>
    </div>

    <!-- <h1>{{specTypeArr }}</h1> -->
  </div>
</template>

<script>
export default {
  name: "defaultSpecPanel",
  props: {
    currentGoodsRank: Number,
    specTypeArr: Array,
    specPriceArr: Array,
    specArray: Array
  },
  data() {
    return {
      totalPrice: 0,
      specificationName: ''
    }
  },
  mounted(){
    this.$nextTick(() => {
      let len = this.specTypeArr.length
      console.log('len',len);
      if (len === 0) {
        this.specificationName = "默认规格"
      } else if (len === 1) {
        this.specificationName = this.specTypeArr[0].specificationNameArr[0].specificationName
      } else if (len == 2) {
        this.specificationName = `${this.specTypeArr[0].specificationNameArr[0].specificationName}(${this.specTypeArr[1].specificationNameArr.map(item => item.specificationName).join(',')})`
      }
      console.log('this.specTypeArr[0].specificationNameArr[0]', this.specTypeArr);
      console.log('specificationName',this.specificationName);
    })
  },
  computed: {},
  watch: {
    specPriceArr: {
      handler(val) {
        if (val.length < 1) return
        // 单品默认
        if (this.currentGoodsRank === 1) {
          this.totalPrice = this.specPriceArr.reduce((total, item) => total + (isNaN(+item.goodsPrice) ? 0 : +item.goodsPrice), 0)
          // 套餐默认
        } else if (this.currentGoodsRank === 3) {
          this.totalPrice = this.specPriceArr.reduce((total, item) => total + (isNaN(+item.goodsDiscountPrice) ? 0 : +item.goodsDiscountPrice), 0)
        }
      },
      immediate: true
    },
    specTypeArr(val) {
      // alert("规格名字")
      console.log("规格名字", this.specTypeArr)
      let len = val.length
      console.log("规格名字len", len)
      if (len === 0) {
        this.specificationName = "默认规格"
      } else if (len === 1) {
        this.specificationName = this.specTypeArr[0].specificationNameArr[0].specificationName
      } else if (len == 2) {
        this.specificationName = `${this.specTypeArr[0].specificationNameArr[0].specificationName}(${this.specTypeArr[1].specificationNameArr.map(item => item.specificationName).join(',')})`
      }
      console.log('specificationName监听',this.specificationName);
    },
  }
}
</script>

<style lang="scss" scoped>
.single-default {
  position: relative;
  margin-bottom: 14px;
  .default {
    display: inline-block;
    padding: 0 24px;
    height: 30px;
    line-height: 30px;
    color: #337AFF;
    background: #ffffff;
    border: 1px solid #337AFF;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
  }
  .single-icon {
    position: absolute;
    left: 87px;
    top: 0;
  }
  .content {
    margin-bottom: 18px;
  }
  .cate-name {
    flex-shrink: 0;
    margin-right: 24px;
    margin-bottom: 12px;
    font-size: 14px;
    color: #999999;
    white-space: nowrap;
  }
  .spec-list {
    display: flex;
    flex-wrap: wrap;
  }
  .spec-item {
    display: inline-block;
    position: relative;
    height: 30px;
    line-height: 30px;
    padding: 0 25px;
    text-align: center;
    margin-right: 12px;
    border: 1px solid #337AFF;
    border-radius: 3px;
    cursor: pointer;
    .spec-name {
      font-size: 14px;
      color: #337AFF;
    }
    > img {
      position: absolute;
      right: -1px;
      top: 0;
    }
  }
}
.hr {
  width: 616px;
  margin: 0 auto;
  margin-bottom: 30px;
  border-bottom: 1px dashed #e0e0e0;
}
.already-select {
  margin-bottom: 18px;
  > h3 {
    margin-right: 24px;
    margin-bottom: 12px;
    font-size: 14px;
    color: #999;
  }
  .select-list {
    .select-item {
      font-size: 14px;
      color: #333;
      margin-bottom: 12px;
    }
  }
}
.price-line {
  text-align: right;
  .price-title {
    margin-bottom: 12px;
    font-size: 14px;
    color: #999;
  }
  .price-text {
    color: #f54955;
    font-size: 18px;
    font-weight: 700;
  }
}
.spec-bottom{
  display: flex;
  justify-content: space-between;
}
</style>
