var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "single-default-wrap" }, [
    _vm.specTypeArr.length <= 1
      ? _c("div", { staticClass: "single-default" }, [
          _c("div", { staticClass: "spec-item" }, [
            _vm.specTypeArr[0]
              ? _c("div", { staticClass: "spec-name" }, [
                  _vm._v(
                    _vm._s(
                      _vm.specTypeArr[0].specificationNameArr[0]
                        .specificationName
                    )
                  )
                ])
              : !_vm.specTypeArr.length && this.specificationName
              ? _c("div", { staticClass: "spec-name" }, [
                  _vm._v(_vm._s(this.specificationName))
                ])
              : _vm._e(),
            _c("img", {
              attrs: {
                src: require("@/assets/image/select-ok-mini.png"),
                alt: ""
              }
            })
          ])
        ])
      : _vm.specTypeArr.length > 1
      ? _c(
          "div",
          { staticClass: "single-default" },
          _vm._l(_vm.specTypeArr, function(item, index) {
            return _c("div", { key: item.id, staticClass: "content" }, [
              _c("h2", { staticClass: "cate-name" }, [
                _vm._v(_vm._s(item.specificationTypeName))
              ]),
              _c(
                "div",
                { staticClass: "spec-list" },
                [
                  _vm._l(item.specificationNameArr, function(subItem) {
                    return [
                      index == 1 &&
                      _vm.specArray[0] &&
                      _vm.specArray[0].secondSpNameList.some(function(i) {
                        return i.spNameId == subItem.id
                      })
                        ? _c(
                            "div",
                            { key: subItem.id, staticClass: "spec-item" },
                            [
                              _c("div", { staticClass: "spec-name" }, [
                                _vm._v(_vm._s(subItem.specificationName))
                              ]),
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/image/select-ok-mini.png")
                                }
                              })
                            ]
                          )
                        : _vm._e(),
                      index == 0
                        ? _c(
                            "div",
                            { key: subItem.id, staticClass: "spec-item" },
                            [
                              _c("div", { staticClass: "spec-name" }, [
                                _vm._v(_vm._s(subItem.specificationName))
                              ]),
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/image/select-ok-mini.png")
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ])
          }),
          0
        )
      : _vm._e(),
    _c("div", { staticClass: "hr" }),
    _c("div", { staticClass: "spec-bottom" }, [
      _c("div", { staticClass: "already-select" }, [
        _c("h3", [_vm._v("已选")]),
        _c(
          "ul",
          { staticClass: "select-list" },
          [
            _c("transition-group", { attrs: { name: "label" } }, [
              _vm.specTypeArr[0]
                ? _c(
                    "li",
                    { key: "specificationName", staticClass: "select-item" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.specificationName ||
                              _vm.specTypeArr[0].specificationNameArr[0]
                                .specificationName
                          )
                        )
                      ])
                    ]
                  )
                : !_vm.specTypeArr.length && _vm.specificationName
                ? _c(
                    "li",
                    { key: "specificationName", staticClass: "select-item" },
                    [_c("span", [_vm._v(_vm._s(_vm.specificationName))])]
                  )
                : _vm._e()
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "price-line" }, [
        _c("p", { staticClass: "price-title" }, [_vm._v("价格")]),
        _c("p", { staticClass: "price-text" }, [
          _vm._v(_vm._s("￥" + (_vm.totalPrice || 0)))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }