<!--
 * @Author: 刘锦
 * @Date: 2020-11-07 20:52:31
 * @LastEditTime: 2022-02-22 14:51:44
 * @LastEditors: Seven
 * @Description: 
 * @FilePath: \workbench-front\src\components\business\meal-more-spec-panel.vue
-->
<template>
  <div class="meal-more-spec-wrap">
    <!-- {{specIdTwoArr}} -->
    <!-- <p>{{specIdTwoArr}}</p> -->
    <div class="meal-one" v-if="specTypeArr.length === 1">
      <div class="content" v-for="item of specTypeArr" :key="item.id">
        <h2 class="cate-name">{{ item.specificationTypeName }}</h2>
        <div class="spec-list">
          <template v-for="subItem of item.specificationNameArr">
            <div
              v-if="specArray.some(i => i.id == subItem.id)"
              :key="subItem.id"
              :class="['spec-item', { active: specNameIdArr.includes(subItem.id) }]"
              @click="onSpecItemClick(subItem)"
            >
            <div class="spec-name">{{ subItem.specificationName }}</div>
            <img src="@/assets/image/select-ok-mini.png" v-show="specNameIdArr.includes(subItem.id)" />
          </div>
          </template>
        </div>
      </div>
    </div>
    <div class="meal-two" v-else-if="specTypeArr.length === 2">
      <div class="content">
        <!-- 一级 -->
        <h2 class="cate-name">{{ specTypeArr[0].specificationTypeName }}</h2>
        <div class="spec-list">
          <template  v-for="subItem of specTypeArr[0].specificationNameArr">
             <div
                v-if="specArray.some(i => i.id == subItem.id)"
                :class="['spec-item', { active: subItem.id === curSelectedSpecIdOne }]"
                :key="subItem.id"
                @click="onSpecOneItemClick(subItem, true)"
              >
                <div class="spec-name">{{ subItem.specificationName }}</div>
                <img src="@/assets/image/select-ok-mini.png" v-show="subItem.id === curSelectedSpecIdOne" />
              </div>
          </template>
         
        </div>
      </div>


      <div class="content">
        <!-- AAA -->
        <h2 class="cate-name">{{ specTypeArr[1].specificationTypeName }}</h2>
        <div class="spec-list" v-if="specTypeArray.length!==0">
          <!-- A -->
          <div
            v-for="subItem of specTypeArray[1].specificationNameArr"
            :key="subItem.spNameId"
            :class="['spec-item', { active: specIdTwoArr.includes(subItem.spNameId) }]"
            @click="onSpecTwoItemClick(subItem)"
          >
            <div class="spec-name">{{ subItem.spName }}</div>
            <img src="@/assets/image/select-ok-mini.png" v-show="specIdTwoArr.includes(subItem.spNameId)" />
          </div>
        </div>

        <div class="spec-list" v-else>
          <!-- B -->
          <div
            v-for="subItem of specTypeArr[1].specificationNameArr"
            :key="subItem.id"
            :class="['spec-item', { active: specIdTwoArr.includes(subItem.id) }]"
            @click="onSpecTwoItemClick(subItem)"
          >
            <div class="spec-name">{{ subItem.specificationName }}</div>
            <img src="@/assets/image/select-ok-mini.png" v-show="specIdTwoArr.includes(subItem.id)" />
          </div>
        </div>

      </div>
    </div>
    <div class="hr"></div>
    <div class="spec-bottom">
      <div class="already-select">
        <h3>已选</h3>
        <ul class="select-list">
          <transition-group name="label">
            <li class="select-item" key="specificationName">
              <span>{{ specificationName }}</span>
            </li>
          </transition-group>
        </ul>
      </div>
      <div class="price-line">
        <p class="price-title">价格</p>
        <p class="price-text">{{ "￥" + (totalPrice || 0) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MealMoreSpecPanel",
  props: {
    currentGoodsRank: Number,
    specTypeArr: Array,
    specPriceArr: Array,
    specCountArr: Array,
    specArray: Array
  },
  data() {
    return {
      curSelectedSpecId: "",
      curSelectedSpecIdOne: "",
      curSelectedSpecIdTwo: "",
      totalPrice: 0,
      // 套餐多规格 类型数组为长度为1 ,的specid数组
      specNameIdArr: [],
      // 真正的specId数组
      specIdArr: [],
      // 套餐多规格，类型数组为长度为1 ,第二行的specid数组
      specIdTwoArr: [],
      specArr: [],
      specTypeArray: [],//存储第二行
      currentLimitCountNum: 0,
      specificationName: ''
    }
  },
  watch: {
    // 默认选中第一个
    specTypeArr(data) {
      console.log("$bus------------",this.$bus)
      // if(this.$bus._priceChange) return
      if (data.length === 1) {
        // 班型只有一级，默认选择项目实现过程
        try {
          let currentLimitCount = this.specCountArr[0].selectCount
          if(currentLimitCount !== null && currentLimitCount !== '' && currentLimitCount >= 0){
            data[0].specificationNameArr.slice(0, currentLimitCount === 0 ? 1 : currentLimitCount).forEach(spec=> {
              this.onSpecItemClick(spec)
            })
          }else{
            this.onSpecItemClick(data[0].specificationNameArr[0])
          }
        } catch (error) {
          this.onSpecItemClick(data[0].specificationNameArr[0])
        }
      } else if (data.length === 2) {
        this.onSpecOneItemClick(data[0].specificationNameArr[0])
      }
    },
    specArray(data) {
      if (this.specTypeArray.length === 2) {
        if(!data[0].secondSpNameList) return//修复从多级商品切换成一级商品报错
        this.specTypeArray[1].specificationNameArr = data[0].secondSpNameList
        try {
          // 获取二级可以任选n项
          let currentLimitCount = this.specCountArr.find(item => item.specificationId === this.curSelectedSpecIdOne).selectCount
          if(currentLimitCount !== null && currentLimitCount !== '' && currentLimitCount >= 0){
            this.specTypeArray[1].specificationNameArr.slice(0, currentLimitCount === 0 ? 1 : currentLimitCount).forEach(spec=> {
              this.onSpecTwoItemClick(spec)
            })
          }else{
            this.onSpecTwoItemClick(data[0].secondSpNameList[0])
          }
        } catch (error) {
          this.onSpecTwoItemClick(data[0].secondSpNameList[0])
        }
      }
    },
    specCountArr(val) {
      // console.log('watch val', val);
    }
  },
  methods: {
    // 套餐多规格 类型数组为长度为1 点击
    onSpecItemClick(spec) {
      // console.log('spec规格', spec)
      const index = this.specNameIdArr.indexOf(spec.id)
      if (index > -1) {
        this.specNameIdArr.splice(index, 1)
      } else {
        this.specNameIdArr.push(spec.id)
      }
      // console.log('this.specNameIdArr', this.specNameIdArr)
      // 判断用户选择的规格是否达到了限制的规格 如果是，则删除数组最后一个元素，并返回
      if (this.specNameIdArr.length > this.specCountArr[0].selectCount && this.specTypeArr.length > 1) {
        this.specNameIdArr.pop()
        return this.$message.error("数量超出该套餐可选最大规格数")
      }
      const currentSpecArr = this.specPriceArr.filter(item => this.specNameIdArr.includes(item.specificationNameId1))
      this.specificationName = this.getSpecificationName(currentSpecArr)
      this.specIdArr = this.getSpecIdArr(currentSpecArr)
      this.totalPrice = this.getTotalPrice(currentSpecArr)
      this.specArr = currentSpecArr
      // console.log(this.specIdArr)
    },
    // 套餐多规格 类型数组为长度为2  第一行点击
    // isUserTouch: true用户主动触发
    onSpecOneItemClick(spec, isUserTouch) {
      console.log(spec, '88888888888888888888888888888888');
      let isSameAction = spec.id === this.curSelectedSpecIdOne
      if( spec.isStop ){
        this.$message.error("此班型已经停用,不允许使用")
        return;
      }
      console.log('spec 套餐多规格', spec, this.specArray)
      const curOneItem = this.specArray.find(item => item.id === spec.id)
      if(curOneItem){
        let priceArr = []
        if(curOneItem.secondSpNameList.length > 0){
          const curOnePriceArray = this.specPriceArr.filter(item => item.specificationNameId1 === spec.id)
          curOneItem.secondSpNameList.map(oItem => {
            const hit = curOnePriceArray.find(item => item.specificationNameId2 === oItem.spNameId)
            if(hit){
              priceArr.push(hit)
            }
          })
        }
        console.log('[priceArr]', priceArr);
        if(!!priceArr.length){
          const disabledArr = priceArr.filter(item => item.status === 0)
          if(disabledArr.length === priceArr.length){
            // 二级全部已经禁用
            return this.$message.error("此班型已经停用,不允许使用")
          }
        }
      }

      this.curSelectedSpecIdOne = spec.id

      this.$bus.$emit("setFirstCateInfo",spec);
      // this.$bus.$emit("changeSel",spec);
      this.specTypeArray = this.specTypeArr
      this.specArray.map((item => {
        if (item.id == spec.id) {
          this.specTypeArray[1].specificationNameArr = item.secondSpNameList
        }
      }))
      // console.log('specTypeArray', this.specTypeArray)
      // console.log('this.specTypeArr', this.specTypeArr);
      this.specIdTwoArr = []
      this.specificationName = ''
      this.totalPrice = 0
      this.specArr = []
      try {
        // 用户主动点击一级才执行以下流程
        if(!isUserTouch) return
        if(isSameAction) return
        let currentLimitCount = this.specCountArr.find(item => item.specificationId === this.curSelectedSpecIdOne).selectCount
        if(currentLimitCount >= 0){
          this.specTypeArray[1].specificationNameArr.slice(0, currentLimitCount === 0 ? 1 : currentLimitCount).forEach(spec=> {
              this.onSpecTwoItemClick(spec)
            })
        }
      } catch (error) {
        
      }
    },
    // 套餐多规格 类型数组为长度为2  第二行点击
    onSpecTwoItemClick(spec) {
      console.log('spec: ', spec)
      // 第一行不选，无法选第二行
      if (!this.curSelectedSpecIdOne) {
        return this.$message.error('请选择第一行')
      }
      if(this.curSelectedSpecIdOne !== ''){
        const specTwoItem = this.specPriceArr.find(item => item.specificationNameId1 == this.curSelectedSpecIdOne && spec.spNameId === item.specificationNameId2)
        console.log('[specTwoItem]', specTwoItem, specTwoItem.specificationName1 ,specTwoItem.specificationName2 ,specTwoItem.status);
        // 	status规格状态：0停用，1启用
        if(specTwoItem && specTwoItem.status === 0){
          return this.$message.error("该项已停用")
        }
      }
      if (this.specTypeArray.length !== 0) {
        const index = this.specIdTwoArr.indexOf(spec.spNameId)
        if (index > -1) {
          this.specIdTwoArr.splice(index, 1)
        } else {
          this.specIdTwoArr.push(spec.spNameId)
        }
      } else {
        const index = this.specIdTwoArr.indexOf(spec.id)
        if (index > -1) {
          this.specIdTwoArr.splice(index, 1)
        } else {
          this.specIdTwoArr.push(spec.id)
        }
      }
      // console.log(this.specCountArr)
      let currentLimitCount = this.specCountArr.find(item => item.specificationId === this.curSelectedSpecIdOne).selectCount
      // console.log(this.specIdTwoArr)
      // console.log('currentLimitCount长度', currentLimitCount, this.specIdTwoArr.length)
      if (currentLimitCount !== 0 && this.specIdTwoArr.length > currentLimitCount) {
        this.specIdTwoArr.pop()
        return this.$message.error("数量超出该套餐可选最大规格数")
      } else if (currentLimitCount !== 0 && this.specIdTwoArr.length < currentLimitCount) {
        //  this.$message.error(`至少选择${currentLimitCount}项科目`)
        // console.log('currentLimitCount子组件', currentLimitCount);
        this.currentLimitCountNum = currentLimitCount
      }
      const currentSpecArr = this.specPriceArr.filter(
        item => item.specificationNameId1 === this.curSelectedSpecIdOne && this.specIdTwoArr.includes(item.specificationNameId2)
      )
      this.specificationName = this.getSpecificationName(currentSpecArr)
      this.specIdArr = this.getSpecIdArr(currentSpecArr)
      this.totalPrice = this.getTotalPrice(currentSpecArr)
      this.specArr = currentSpecArr
      // console.log(this.specIdArr)

      this.$bus.$emit("setSecondCateInfo",this.specIdTwoArr);
    },
    getSpecificationName(currentSpecArr) {
      if(this.specTypeArr.length > 1){
        let arr = []
        currentSpecArr.forEach(item => {
          arr.push(item.specificationName2)
        })
        let str = arr.length ? `(${arr.join('、')})` : ''
        return currentSpecArr.length ?  currentSpecArr[0].specificationName1 + str : ''
      }else{
        //存在只有规格一项的情况
        let result = ''
        if(!currentSpecArr) return result
        currentSpecArr.reduce((pre, cur) => result += cur.specificationName1 + ', ', result)
        return result.replace(/, $/, '')
      }
    },
    getTotalPrice(currentSpecArr) {
      let sum = 0
      for (const item of currentSpecArr) {
        sum += item.goodsDiscountPrice
      }
      return Number(sum).toFixed(2)
    },
    getSpecIdArr(currentSpecArr) {
      return currentSpecArr.map(item => item.id)
    },
    //商品价格变动时自动选择
    autoSelOnPriceChange(){
      // console.log("查询",this.specTypeArray[1].specificationNameArr)
      // console.log("查询一级类",this.specTypeArr)
      // this.curSelectedSpecIdOne = val.id
      // console.log("商品价格变动>>>>>>>>>>>>>>>>>",val,this.curSelectedSpecIdOne);
      // for(let i of this.$bus)
      //模拟手动点击，找回商品的大类
      this.onSpecOneItemClick(this.$bus.firstCateInfo)
      //找回商品的小类
      for(let i of this.specTypeArray[1].specificationNameArr){
        if(this.$bus.secondCateInfo.includes(i.spNameId)){
          this.onSpecTwoItemClick(i)
        }
      }
    }
  },
  mounted(){

    this.$bus.$on("autoSelOnPriceChange",this.autoSelOnPriceChange);
  }
}
</script>

<style lang="scss" scoped>
.meal-one {
  position: relative;
  margin-bottom: 14px;
  .default {
    display: inline-block;
    padding: 0 24px;
    height: 30px;
    line-height: 30px;
    color: #337AFF;
    background: #ffffff;
    border: 1px solid #337AFF;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
  }
  .meal-icon {
    position: absolute;
    left: 87px;
    top: 0;
  }
  .content {
    margin-bottom: 18px;
    .cate-name {
      margin-right: 24px;
      margin-bottom: 12px;
      font-size: 14px;
      color: #999999;
      white-space: nowrap;
    }
    .spec-list {
      display: flex;
      flex-wrap: wrap;
    }
    .spec-item {
      display: inline-block;
      position: relative;
      width: fit-content;
      height: 30px;
      line-height: 30px;
      padding: 0 24px;
      text-align: center;
      margin-right: 12px;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      color: #666666;
      cursor: pointer;
      min-width: 26px;
      .spec-name {
        font-size: 14px;
      }

      > img {
        position: absolute;
        right: -1px;
        top: 0;
      }
    }
    .active {
      color: #337AFF;
      border: 1px solid #337AFF;
    }
  }
}
.meal-two {
  position: relative;
  margin-bottom: 14px;
  .default {
    display: inline-block;
    padding: 0 24px;
    height: 30px;
    line-height: 30px;
    color: #337AFF;
    background: #ffffff;
    border: 1px solid #337AFF;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
  }
  .single-icon {
    position: absolute;
    left: 87px;
    top: 0;
  }
  .content {
    margin-bottom: 18px;
    .cate-name {
      flex-shrink: 0;
      margin-right: 24px;
      margin-bottom: 12px;
      font-size: 14px;
      color: #999999;
      white-space: nowrap;
    }
    .spec-list {
      display: flex;
      flex-wrap: wrap;
      height: 72px;
    }
    .spec-item {
      display: inline-block;
      position: relative;
      width: fit-content;
      height: 30px;
      line-height: 30px;
      padding: 0 24px;
      margin-right: 12px;
      margin-bottom: 12px;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      color: #666666;
      cursor: pointer;
      .spec-name {
        font-size: 14px;
      }

      > img {
        position: absolute;
        right: -1px;
        top: 0;
      }
    }
    .active {
      color: #337AFF;
      border: 1px solid #337AFF;
    }
  }
}
.hr {
  width: 616px;
  margin: 0 auto;
  margin-bottom: 30px;
  border-bottom: 1px dashed #e0e0e0;
}
.price-line {
  text-align: right;
  .price-title {
    margin-bottom: 12px;
    font-size: 14px;
    color: #999999;
  }
  .price-text {
    color: #f54955;
    font-size: 18px;
    font-weight: 700;
  }
}
.spec-bottom{
  display: flex;
  justify-content: space-between;
}
.select-list{
  min-height: 15px;
}
</style>
